import 'owl.carousel';

export default function block_events_carousel() {

	function bind_events_carousel() {
		$( '.fpc-events-carousel-block .owl-carousel' ).owlCarousel( {
			items: 1,
			loop: true,
			dots: false,
			nav: true,
			autoHeight: false,
			autoplay: true,
			autoplayTimeout: 4000,
			autoplaySpeed: 1500,
			autoplayHoverPause: true,
			navText: [
				'<i class="fas fa-angle-left"></i><span class="sr-only">Prev</span>',
				'<i class="fas fa-angle-right"></i><span class="sr-only">Next</span>'
			]
		} );
	}

	if ( window.acf ) {
		window.acf.addAction( 'render_block_preview/type=fpc-events-carousel', function ( block ) {
			bind_events_carousel();
		} );
	} else {
		bind_events_carousel();
	}
}
