import 'owl.carousel';

export default function block_merch_carousel() {

	function bind_merch_carousel() {
		$( '.fpc-merch-block .owl-carousel' ).owlCarousel( {
			items: 3,
			loop: true,
			dots: false,
			nav: true,
			navText: [
				'<i class="fas fa-angle-left"></i><span class="sr-only">Prev</span>',
				'<i class="fas fa-angle-right"></i><span class="sr-only">Next</span>'
			],
			margin: 30,
			responsive: {
				0: {
					autoHeight: true,
					items: 1
				},
				768: {
					items: 2
				},
				992: {
					items: 3
				}
			}
		} );
	}

	if ( window.acf ) {
		window.acf.addAction( 'render_block_preview/type=fpc-merch', function ( block ) {
			bind_merch_carousel();
		} );
	} else {
		bind_merch_carousel();
	}
}
