import 'owl.carousel';

export default function block_partners_carousel() {

	function bind_partners_carousel() {
		$( '.fpc-partners-block .owl-carousel' ).owlCarousel( {
			items: 6,
			loop: true,
			dots: false,
			nav: false,
			margin: 60,
			autoplay: true,
			autoplayHoverPause: false,
			autoplayTimeout: 1000,
			autoHeight: true,
			smartSpeed: 500,
			responsive: {
				0: {
					items: 1
				},
				480: {
					items: 2
				},
				768: {
					items: 3,
					margin: 30
				},
				992: {
					items: 6
				}
			}
		} );
	}

	if ( window.acf ) {
		window.acf.addAction( 'render_block_preview/type=fpc-partners', function ( block ) {
			bind_partners_carousel();
		} );
	} else {
		bind_partners_carousel();
	}
}
