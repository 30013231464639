export default function block_faqs() {

	function bind_faqs() {
		$( '.faq > .faq-title > a' ).on( 'click', function ( e ) {
			e.preventDefault();
			$( this ).find( 'i' ).toggleClass( 'fa-circle-plus' ).toggleClass( 'fa-circle-minus' );
			$( this ).parent().next( '.faq-content' ).slideToggle();
		} );
	}

	if ( window.acf ) {
		window.acf.addAction( 'render_block_preview/type=fpc-faqs', function ( block ) {
			bind_faqs();
		} );
	} else {
		bind_faqs();
	}
}
