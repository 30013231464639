export default function ads() {

	function bind_ads() {
		$( '.fpc-ads-block, .event-ad>.event-inner:not(.loaded)' ).each( function () {
			const ads = $( this ).find( 'a' );
			if ( ads ) {
				const ad = ads[Math.floor( Math.random() * ads.length )];
				$( ad ).css( 'display', 'block' );
				$( ad ).parent( '.event-inner' ).addClass( 'loaded' );
			}
		} );

		$( '.photo-gallery-ad>.photo-gallery-inner:not(.loaded)' ).each( function () {
			const ads = $( this ).find( 'a' );
			if ( ads ) {
				const ad = ads[Math.floor( Math.random() * ads.length )];
				$( ad ).css( 'display', 'block' );
				$( ad ).parent( '.photo-gallery-inner' ).addClass( 'loaded' );
			}
		} );
	}

	if ( window.acf ) {
		window.acf.addAction( 'render_block_preview/type=fpc-ads', function ( block ) {
			bind_ads();
		} );
	} else {
		bind_ads();
	}
}
