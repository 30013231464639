export default function block_photo_galleries() {
	function bind_photo_galleries() {
		$( '.fpc-photo-galleries-grid-more>.btn' ).on( 'click', function ( e ) {
			e.preventDefault();

			const self = $( this );

			const nonce = $( this ).attr( 'data-nonce' );
			const page = parseInt( $( this ).attr( 'data-next-page' ) );
			const posts_per_page = parseInt( $( this ).attr( 'data-posts-per-page' ) );

			$.ajax( {
				method: 'POST',
				url: wp.ajax_url,
				dataType: 'html',
				data: {
					'action': 'fpc_load_photo_galleries',
					'nonce': nonce,
					'page': page,
					'posts_per_page': posts_per_page
				},
				beforeSend: function () {
					self.text( 'Loading...' );
				},
				success: function ( response ) {
					self.attr( 'data-next-page', page + 1 );
					$( '.fpc-events-grid' ).append( response );

					if ( response.includes( 'fpc-photo-galleries-grid-no-photo-galleries' ) ) {
						self.fadeOut();
					}
				},
				complete: function () {
					self.text( 'Load More' );
				},
				error: function () {
					self.fadeOut();
				}
			} );

		} );
	}

	if ( window.acf ) {
		window.acf.addAction( 'render_block_preview/type=fpc-photo-galleries', function ( block ) {
			bind_photo_galleries();
		} );
	} else {
		bind_photo_galleries();
	}
}
