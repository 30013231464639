export default function block_events() {
	function bind_events() {
		$( '.fpc-events-grid-more>.btn' ).on( 'click', function ( e ) {
			e.preventDefault();

			const self = $( this );

			const nonce = $( this ).attr( 'data-nonce' );
			const page = parseInt( $( this ).attr( 'data-next-page' ) );
			const mode = $( this ).attr( 'data-mode' );
			const attractions = $( this ).attr( 'data-attractions' );
			const classifications = $( this ).attr( 'data-classifications' );
			const venues = $( this ).attr( 'data-venues' );
			const posts_per_page = parseInt( $( this ).attr( 'data-posts-per-page' ) );

			$.ajax( {
				method: 'POST',
				url: wp.ajax_url,
				dataType: 'html',
				data: {
					'action': 'fpc_load_events',
					'nonce': nonce,
					'page': page,
					'mode': mode,
					'attractions': attractions,
					'classifications': classifications,
					'venues': venues,
					'posts_per_page': posts_per_page
				},
				beforeSend: function () {
					self.text( 'Loading...' );
				},
				success: function ( response ) {
					self.attr( 'data-next-page', page + 1 );
					$( '.fpc-events-grid' ).append( response );

					if ( response.includes( 'fpc-events-grid-no-events' ) ) {
						self.fadeOut();
					}

					$( '.event-ad>.event-inner:not(.loaded)' ).each( function () {
						const ads = $( this ).find( 'a' );

						if ( ads ) {
							ads.hide();
							const ad = ads[Math.floor( Math.random() * ads.length )];
							$( ad ).css( 'display', 'block' );
							$( ad ).parent( '.event-inner' ).addClass( 'loaded' );
						}
					} );
				},
				complete: function () {
					self.text( 'Load More' );
				},
				error: function () {
					self.fadeOut();
				}
			} );

		} );
	}

	if ( window.acf ) {
		window.acf.addAction( 'render_block_preview/type=fpc-events', function ( block ) {
			bind_events();
		} );
	} else {
		bind_events();
	}
}
